import { Component, OnInit } from '@angular/core';
import {StateService} from '@/_services/state.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.less']
})
export class StartComponent implements OnInit {

  cars;
  selectedCar;

  constructor(
    public state: StateService
  ) {

    this.cars = this.state.getProp('cars');

    if (!this.state.getProp('selectedCar')) {
      this.state.setProp('selectedCar', this.cars[0]);
    }

  }

  ngOnInit() {
    this.selectedCar = this.state.getProp('selectedCar');
  }

  selectCar(car) {
    this.selectedCar = car;
    this.state.setProp('selectedCar', car);
  }
}
