import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {StorageService} from '@/_services/storage.service';
import {StateService} from '@/_services/state.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-select-route',
  templateUrl: './select-route.component.html',
  styleUrls: ['./select-route.component.less']
})
export class SelectRouteComponent implements OnInit {

  selectInsurance = false;
  selectParking = false;
  askParking = false;

  selectedRoute;

  selectedVariant;
  selectedParking;

  route: FormGroup;

  distance = '0';

  time = 0;

  selectedCompany;

  companies = [
    {id: 1, icon: 'alfa.svg', name: 'АльфаСтрахование', stars: 2, comment: 'сумма покрытия до 40 000 ₽', price: 0},
    {id: 2, icon: 'sogaz.svg', name: 'Согаз', stars: 3, comment: 'сумма покрытия до 50 000 ₽', price: 3 },
    {id: 3, icon: 'reso.svg', name: 'Ресо', stars: 3, comment: 'сумма покрытия до 60 000 ₽', price: 8 }
  ];

  parkingVariants = [
    {id: 1, price: 1.2, svg: 'parking-svg-1.svg'},
    {id: 2, price: 1.3, svg: 'parking-svg-2.svg'},
    {id: 3, price: 1.8, svg: 'parking-svg-3.svg'}
  ];

  routeVariants = [
    {id: 1, price: 75, distance: 3.5, time: 12},
    {id: 2, price: 85, distance: 3.1, time: 10},
    {id: 3, price: 98, distance: 3.6, time: 12}
  ];

  addresses = [
    {addr: 'улица Куйбышева, 1/5', distance: 4, time: 11},
    {addr: 'Мичуринская улица, 19', distance: 7.6, time: 7},
    {addr: 'Малая Посадская улица, 16', distance: 2.3, time: 4}
   ];

  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private state: StateService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {

    this.route = this.formBuilder.group({
      startPoint: [{addr: 'Текущее метоположение', distance: 6.1, time: 5}],
      endPoint: ['']
    });

    this.selectedVariant = this.routeVariants[0];
    this.selectedParking = this.parkingVariants[0];
  }

  selectAddress(address) {
    if (!this.route.value.startPoint || !this.route.value.endPoint) {
      document.getElementById("select-route").classList.add("r0");
      setTimeout(()=>{
        document.getElementById("select-route").classList.add("r1");
        document.getElementById("select-route").classList.remove("r0");
      }, 400)
    } else {

    }

    this.route.value[this.selectedRoute] = address;
    this.countRouteValues();
    this.selectedRoute = '';
  }

  countRouteValues() {
    if (this.route.value.startPoint.distance && this.route.value.endPoint.distance) {
      this.distance = parseFloat(this.route.value.startPoint.distance + this.route.value.endPoint.distance).toFixed(1);
      this.time = parseFloat(this.route.value.startPoint.time + this.route.value.endPoint.time);
    }
  }

  selectVariant(variant) {
    this.selectedVariant = variant;
    this.storageService.setTime(this.selectedVariant.time);
    this.storageService.setDistance(this.selectedVariant.distance);
    this.storageService.setTotalPrice(this.selectedVariant.price)
  }

  clearField() {
    this.route.value['endPoint'] = '';
  }

  selectCompany(company) {
    this.selectedCompany = company;
    this.storageService.setTotalPrice(this.selectedCompany.price + this.selectedVariant.price);
    this.storageService.setStrah(this.selectedCompany.name);
  }

  selectParkingVariant(variant) {
    this.selectedParking = variant;
    this.state.setProp('selectedParking', this.selectedParking)
  }

}
