import * as i0 from "@angular/core";
var StorageService = /** @class */ (function () {
    function StorageService() {
        this.variant = {
            distance: 0,
            time: 0,
            price: 0,
            strah: 'АльфаСтрахование'
        };
    }
    StorageService.prototype.setTotalPrice = function (price) {
        this.variant.price = price;
    };
    StorageService.prototype.setDistance = function (distance) {
        this.variant.distance = distance;
    };
    StorageService.prototype.setTime = function (time) {
        this.variant.time = time;
    };
    StorageService.prototype.setStrah = function (strah) {
        this.variant.strah = strah;
    };
    StorageService.prototype.getVariant = function () {
        return this.variant;
    };
    StorageService.ngInjectableDef = i0.defineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: "root" });
    return StorageService;
}());
export { StorageService };
