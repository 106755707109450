import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {HttpClientModule} from '@angular/common/http';
import { fakeBackendProvider } from './_helpers';
import { MomentModule } from 'ngx-moment';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { StartComponent } from '@/start/start.component';
import {ModalDialogComponent} from '@/shared/components/modal-dialog/modal-dialog.component';
import {PageNotFoundComponent} from '@/shared/components/page-not-found/page-not-found.component';
import {LoginModule, LoginComponent} from '@/login';
import {ReactiveFormsModule, FormGroup} from '@angular/forms';
import { SelectRouteComponent } from './select-route/select-route.component';
import { SelectInsuranceComponent } from './select-route/select-insurance/select-insurance.component';
import { NavigatorComponent } from './navigator/navigator.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import {MatStepperModule} from '@angular/material/stepper';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SafeHtmlPipe} from '@/_pipes/safe-html.pipe';
// import { JwtInterceptor, ErrorInterceptor } from './_helpers';

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    ModalDialogComponent,
    PageNotFoundComponent,
    SelectRouteComponent,
    LoginComponent,
    SelectInsuranceComponent,
    NavigatorComponent,
    OnboardingComponent,
    SafeHtmlPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    LoginModule,
    MatStepperModule,
    FormsModule,
    BrowserAnimationsModule,
    MomentModule.forRoot({ relativeTimeThresholdOptions: { m: 59 } })
  ],
  providers: [
    // {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    // {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
