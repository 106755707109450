import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  cars = [
    {id: 1, title: 'Mercedes', number: 'Х 388 НО 178', reit: '8/10', params: [
        {label: 'Модель', value: 'C180'},
        {label: 'Год выпуска', value: '2014'},
        {label: 'Страховая стоимость (р)', value: '912 000'},
        {label: 'VIN', value: '67FGGH9DJJ8549JH7'}
      ],
      carIcon: 'bmw.svg'
    },
    {id: 2, title: 'Ford', number: 'M 123 КК 178', reit: '8/10', params: [
        {label: 'Модель', value: 'C-Max'},
        {label: 'Год выпуска', value: '2015'},
        {label: 'Страховая стоимость (р)', value: '520 000'},
        {label: 'VIN', value: 'J449H81KL86BTS85H'}
      ],
      carIcon: 'ford.svg'
    }
  ];

  selectedCar = this.cars[0];

  constructor() { }

  setProp(name, value) {
    this[name] = value;
  }

  getProp(name) {
    return this[name]
  }
}
