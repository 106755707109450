import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '@/_services';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, route, router, authenticationService) {
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.loading = false;
        this.submitted = false;
        this.error = '';
        this.number = '';
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.loginForm = this.formBuilder.group({
            phone: ['+7 999 234 34 34', Validators.required],
            code: ['', Validators.required]
        });
        // reset login status
        this.authenticationService.logout();
        // get return url from route parameters or default to '/'
        //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    };
    Object.defineProperty(LoginComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.loginForm.controls; },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.onSubmit = function () {
        //this.submitted = true;
        // stop here if form is invalid
        //if (this.loginForm.invalid) {
        //  return;
        //}
        //this.loading = true;
        if (!this.f.code.value) {
            this.number = this.f.phone.value;
            var codeElement_1 = document.getElementsByClassName("code")[0];
            setTimeout(function () {
                codeElement_1.focus();
            }, 0);
        }
        else {
            this.router.navigate(['/select-route']);
        }
        /* this.authenticationService.login(this.f.username.value, this.f.password.value)
          .pipe(first())
          .subscribe(
            data => {
              this.router.navigate([this.returnUrl]);
            },
            error => {
              this.error = error;
              this.loading = false;
            }); */
    };
    return LoginComponent;
}());
export { LoginComponent };
