import { Component, OnInit, } from '@angular/core';
import { StorageService } from '@/_services/storage.service';
import { StateService } from '@/_services/state.service';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.less']
})
export class NavigatorComponent implements OnInit {

  selectedCar;
  counter = false;
  arrived = false;
  rnd;
  variant = {
    distance: 0,
    time: 0,
    price: 0,
    strah: ''
  };

  running = false;
  display;
  results;
  laps = [];
  times = [0, 0, 0];
  time;
  end = false;

  timer = 20000;
  constructor(
    private storageService: StorageService,
    public state: StateService
  ) {

    this.rnd = false;
    this.running = false;
    this.laps = [];

  }

  ngOnInit() {
    let animation = document.getElementById("base64animation");
    let data = document.getElementById("animData").getAttribute("data-animation");
    animation.setAttribute("src", data);

    this.variant = this.storageService.getVariant();
    if (this.state.getProp('selectedParking')) {
      this.timer = 20000;
    }
    setTimeout(() => {
      document.getElementById("navigator").classList.add("arrived");
      this.arrived = true;
      let animation = document.getElementById("base64animation");
      animation.setAttribute("src", '');
    }, this.timer);
    this.rnd = Math.random();

    this.selectedCar = this.state.getProp('selectedCar');

  }

  reset() {
    this.times = [0, 0, 0];
  }

  start() {
    if (!this.time) this.time = performance.now();
    if (!this.running) {
      this.running = true;
      requestAnimationFrame(this.step.bind(this));
    }
  }

  stop() {
    this.running = false;
    this.time = null;
  }

  restart() {
    if (!this.time) this.time = performance.now();
    if (!this.running) {
      this.running = true;
      requestAnimationFrame(this.step.bind(this));
    }
    this.reset();
  }

  clear() {
    this.clearChildren(this.results);
  }

  step(timestamp) {
    if (!this.running) return;
    this.calculate(timestamp);
    this.time = timestamp;
    this.print();
    requestAnimationFrame(this.step.bind(this));
  }

  calculate(timestamp) {
    let diff = timestamp - this.time;

    // Hundredths of a second are 100 ms
    this.times[2] += diff / 1000;

    // Seconds are 100 hundredths of a second
    if (this.times[2] >= 60) {
      this.times[1] += 1;
      this.times[2] -= 60;
    }

    // Minutes are 60 seconds
    if (this.times[1] >= 60) {
      this.times[0] += 1;
      this.times[1] -= 60;
    }
  }

  print() {
    return this.format(this.times);
  }

  format(times) {
    return `\
      ${this.pad0(times[0], 2)}<span class="grey">:</span>\
      ${this.pad0(times[1], 2)}<span class="grey">:</span>\
      ${this.pad1(Math.floor(times[2]), 2)}`;
  }

  pad0(value, count) {
    let result = value.toString();
    for (; result.length < count; --count)
      result = value? '0' + result : '<span class="grey">0' + result + '</span>';
    return result;
  }

  pad1(value, count) {
    let result = value.toString();
    for (; result.length < count; --count)
      result = '0' + result;
    return result;
  }

  clearChildren(node) {
    while (node.lastChild)
      node.removeChild(node.lastChild);
  }

}
