<div class="navigator" id="navigator">
  <img class="animation" [hidden]="arrived" id="base64animation" src="">
  <div class="overlay" [hidden]="!arrived"></div>
  <div class="arrived" [hidden]="!arrived" [ngClass]="counter? 'counter-bg':''">
    <div *ngIf="!counter && !end">Счет за поездку</div>
    <div *ngIf="end" style="visibility: hidden;">Счет за парковку</div>
    <div *ngIf="!counter">21.03.19</div>
    <div class="info" [hidden]="counter">
      <div class="line" [hidden]="end">
        <div>Время:</div><div>{{variant.time}} мин</div>
      </div>
      <div class="line" *ngIf="end">
        <div>Время парковки:</div><div>15 мин</div>
      </div>
      <div class="line" [hidden]="end">
        <div>Расстояние:</div><div>{{variant.distance}} км</div>
      </div>
      <div class="line">
        <div>Коэффициент (k)</div><div>2,3</div>
      </div>
      <div class="line">
        <div>Страховщик:</div><div>{{variant.strah}}</div>
      </div>

      <div class="line" *ngIf="!end" style="font-weight: bold; border-bottom: none">
        <div style="width: 70%">Фактическая стоимость страхования поездки</div><div style="padding-top: 10px;font-size: 20px;font-weight: bold;">{{variant.price}} ₽</div>
      </div>
      <div class="line" *ngIf="end" style="font-weight: bold; border-bottom: none">
        <div style="width: 70%">Фактическая стоимость страхования парковки</div><div style="padding-top: 10px;font-size: 20px;font-weight: bold;">15 ₽</div>
      </div>

    </div>

    <div class="counter" *ngIf="counter">
      <img [hidden]="selectedCar.title !== 'Mercedes'" src="/assets/parkingMercedes.png" />
      <img [hidden]="selectedCar.title !== 'Ford'" src="/assets/parkingFord.png" />
      <div class="stopwatch" [hidden]="end" [innerHTML]="print() | html"></div>
      <div class="stopwatch-labels" [hidden]="end">&nbsp;&nbsp;&nbsp;часы&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;минуты&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;секунды</div>
      <div class="value">{{(times[1] * 1.5).toLocaleString()}}<span>₽</span></div>
    </div>

    <div class="button" *ngIf="!counter">
      <button class="ready trans" (click)="rnd=false;arrived=false" [routerLink]="['/select-route']">Закрыть</button>
      <button style="margin-top: 20px" class="ready" *ngIf="!end && state.getProp('selectedParking')" (click)="counter=true; start();">Включить счетчик парковки</button>
    </div>
    <div class="button" *ngIf="counter">
      <button class="ready" (click)="stop(); end = true; counter=false;">Завершить</button>
    </div>
  </div>

</div>


