import * as i0 from "@angular/core";
var StateService = /** @class */ (function () {
    function StateService() {
        this.cars = [
            { id: 1, title: 'Mercedes', number: 'Х 388 НО 178', reit: '8/10', params: [
                    { label: 'Модель', value: 'C180' },
                    { label: 'Год выпуска', value: '2014' },
                    { label: 'Страховая стоимость (р)', value: '912 000' },
                    { label: 'VIN', value: '67FGGH9DJJ8549JH7' }
                ],
                carIcon: 'bmw.svg'
            },
            { id: 2, title: 'Ford', number: 'M 123 КК 178', reit: '8/10', params: [
                    { label: 'Модель', value: 'C-Max' },
                    { label: 'Год выпуска', value: '2015' },
                    { label: 'Страховая стоимость (р)', value: '520 000' },
                    { label: 'VIN', value: 'J449H81KL86BTS85H' }
                ],
                carIcon: 'ford.svg'
            }
        ];
        this.selectedCar = this.cars[0];
    }
    StateService.prototype.setProp = function (name, value) {
        this[name] = value;
    };
    StateService.prototype.getProp = function (name) {
        return this[name];
    };
    StateService.ngInjectableDef = i0.defineInjectable({ factory: function StateService_Factory() { return new StateService(); }, token: StateService, providedIn: "root" });
    return StateService;
}());
export { StateService };
