import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  variant = {
    distance: 0,
    time: 0,
    price: 0,
    strah: 'АльфаСтрахование'
  };

  constructor() { }

  setTotalPrice(price) {
    this.variant.price = price;
  }
  setDistance(distance) {
    this.variant.distance = distance;
  }
  setTime(time) {
    this.variant.time = time;
  }
  setStrah(strah) {
    this.variant.strah = strah;
  }

  getVariant() {
    return this.variant;
  }

}
