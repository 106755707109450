import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {StartComponent} from './start/start.component';
import {LoginComponent} from '@/login';
import {SelectRouteComponent} from '@/select-route/select-route.component';
import {NavigatorComponent} from '@/navigator/navigator.component';
import {OnboardingComponent} from '@/onboarding/onboarding.component';


const routes: Routes = [
  {
    path: '',
    component: OnboardingComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'user',
    component: StartComponent
  },
  {
    path: 'select-route',
    component: SelectRouteComponent
  },
  {
    path: 'navigate',
    component: NavigatorComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
