﻿<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="form-group center" *ngIf="!number">
      <label>Введите номер телефона</label>
      <input placeholder="+7 _ _ _  _ _ _  _ _  _ _" type="text" formControlName="phone" class="form-control number"  />
    </div>

  <div class="form-group center" [hidden]="!number">
    <label>Введите код из смс,<br />отправленного на номер</label>
    <div class="number">{{number}}</div>
    <input placeholder="_ _ _  _ _ _" type="text" formControlName="code" class="form-control code"  />
    <span>Отправить код еще раз</span>
  </div>

    <div class="form-group bottom">
        <button [disabled]="loading" class="btn btn-primary">Далее</button>
        <div class="license">
          <span>Нажимая "Далее", я соглашаюсь с</span><br />
          <span class="agreement">Пользовательским соглашением</span>
        </div>
    </div>

</form>
